import * as React from "react";

import * as ArrayUtils from "Utils/ArrayUtils";
import * as StyleUtils from "Utils/StyleUtils";
import AWSLogo from "../images/aws_logo.svg";
import AzureLogo from "../images/azure_logo.svg";
import DashboardScreenshot from "../images/DashboardScreenshot.png";
import TrieveLogo from "../images/trieve_logo.png";
import DockerLogo from "../images/docker_logo.svg";
import GCPLogo from "../images/gcp_logo.svg";
import GoLogo from "../images/go_logo.svg";
import JavaLogo from "../images/java_logo.svg";
import KubernetesLogo from "../images/kubenetes_logo.svg";
import NickProfilePicture from "../images/NickProfilePicture.jpg";
import NodejsLogo from "../images/nodejs_logo.svg";
import PhpLogo from "../images/php_logo.svg";
import RubyLogo from "../images/ruby_logo.svg";
import RustLogo from "../images/rust_logo.svg";
import PythonLogo from "../images/python_logo.svg";
import YCLogo from "../images/yc_logo.svg";
import XLogo from "../images/x_logo.svg";
import { ExternalLink } from "DesignComponents/ExternalLink";
import { NavLinks } from "NavLinks";

function Orbit(props: { children: React.ReactElement[]; radius: number; numberOfItems: number }): React.ReactElement | null {
  const size = props.children.length ?? 1;
  const { numberOfItems } = props;

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full">
      <div
        className={StyleUtils.mergeClassNames(
          "pointer-events-auto relative rounded-full border border-dashed border-zinc-700",
          props.radius > 300 ? "animate-spin-slow-2" : "animate-spin-slow-1",
          props.radius > 300 ? "rotate-90" : "",
        )}
        style={{
          top: `calc(120% - ${props.radius}px)`,
          left: `calc(50% - ${props.radius}px)`,
          width: `${2 * props.radius}px`,
          height: `${2 * props.radius}px`,
        }}
      >
        {ArrayUtils.extendByRepetition(props.children, numberOfItems).map((_element, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              transform: `rotate(${90 - (i * 360) / numberOfItems}deg)`,
              left: `calc(50% - 16px + ${props.radius * Math.cos((i * 2 * Math.PI) / numberOfItems)}px)`,
              top: `calc(50% - 16px - ${props.radius * Math.sin((i * 2 * Math.PI) / numberOfItems)}px)`,
            }}
          >
            {props.children[i % size]}
          </div>
        ))}
      </div>
    </div>
  );
}

function Integrations(): React.ReactElement {
  function Logo(props: { name: string; src: string; href?: string }): React.ReactElement {
    const ret = (
      <div className="w-7 h-7 group relative">
        <div className="absolute w-full h-full flex justify-center items-center">
          <img className="absolute w-full transition ease-in-out hover:brightness-[1.20]" src={props.src} alt={props.name} />
        </div>
        <span className="whitespace-pre hidden group-hover:block select-none absolute left-[50%] translate-x-[-50%] -top-7 text-center text-[10px] font-medium leading-[10px] bg-zinc-800 text-zinc-400 px-2 py-1 rounded-sm border border-zinc-700">
          {props.name}
        </span>
      </div>
    );

    if (props.href) {
      return (
        <a target="_blank" href={props.href}>
          {ret}
        </a>
      );
    }
    return ret;
  }

  return (
    <div className="overflow-hidden absolute left-0 top-0 w-full h-full">
      <div className="relative top-0 left-0 w-full h-full">
        <div
          className="absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem]"
          style={{
            background: "radial-gradient(#07598560 0%, #07598540 25%, transparent 50%, transparent 100%)",
          }}
        />
        <Orbit radius={360} numberOfItems={16}>
          <Logo name="Node.js" src={NodejsLogo} href="https://docs.subtrace.dev/nodejs-express" />
          <Logo name="Go" src={GoLogo} href="https://docs.subtrace.dev/golang" />
          <Logo name="Ruby" src={RubyLogo} />
          <Logo name="Java" src={JavaLogo} />
          <Logo name="PHP" src={PhpLogo} href="https://docs.subtrace.dev/php-laravel" />
          <Logo name="Rust" src={RustLogo} />
          <Logo name="Python" src={PythonLogo} href="https://docs.subtrace.dev/python-fastapi" />
        </Orbit>
        <Orbit radius={240} numberOfItems={12}>
          <Logo name="Docker" src={DockerLogo} />
          <Logo name="Kubernetes" src={KubernetesLogo} href="https://docs.subtrace.dev/kubernetes" />
          <Logo name="AWS" src={AWSLogo} />
          <Logo name="Azure" src={AzureLogo} />
          <Logo name="Google Cloud" src={GCPLogo} />
        </Orbit>
      </div>
    </div>
  );
}

export function LandingPage(): React.ReactElement {
  return (
    <div className="w-full h-vh flex flex-col px-4 lg:px-0">
      <div className="flex justify-center items-center">
        <NavLinks />
      </div>

      <div className="mt-16 flex justify-center">
        <h2 className="font-semibold whitespace-pre text-3xl sm:text-4xl md:text-5xl space-y-1 lg:space-y-2 w-full text-center lg:max-w-xl flex flex-col items-center px-8 lg:px-4 text-zinc-100 leading-[1.30]">
          <span>Connect your backend</span>
          <span>to Chrome DevTools</span>
        </h2>
      </div>

      <div className="mt-8 flex justify-center">
        <div className="text-zinc-500 text-md max-w-[28rem] text-center">
          Subtrace gives you a livestream of API requests so that you can inspect headers, payload, latency, and more.
        </div>
      </div>

      <div className="mt-16 flex items-center flex-col space-y-4">
        <a href="/login" className="text-sm text-zinc-700 font-medium rounded bg-zinc-100 hover:brightness-[0.90] group px-8 py-4 w-full max-w-[24rem] text-[16px] text-center">
          <span>Try for free</span>
          <span className="inline-block ml-2 transition ease-in-out translate-x-[-1px] group-hover:translate-x-[+1px]">&rarr;</span>
        </a>
      </div>

      <div className="mt-12 flex justify-center group">
        <div className="flex justify-center space-x-3">
          <img src={YCLogo} className="w-4 h-4" alt="Backed by Y Combinator" />
          <span className="text-[11px] text-zinc-500 group-hover:text-zinc-300 transition-all ease-in-out duration-300 font-medium">Backed by Y Combinator</span>
        </div>
      </div>

      <div className="mt-24 flex justify-center md:px-4">
        <img src={DashboardScreenshot} className="w-full max-w-4xl" />
      </div>

      <div className="mt-48 flex justify-center">
        <div className="flex flex-col space-y-4 md:space-y-8 max-w-[100%]">
          <div className="flex space-x-8 items-center text-xl font-medium">
            <div className="relative overflow-hidden w-full h-96 px-8 py-6 bg-zinc-900/70 rounded-xl">
              <span className="text-zinc-300">Works everywhere.</span>
              <span> </span>
              <span className="text-zinc-400">Any language. Any cloud provider.</span>
              <Integrations />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 space-y-4 md:space-y-0 md:space-x-4 items-center text-lg font-medium">
            <div className="relative overflow-hidden w-[calc(100vw-32px)] h-[calc(100vw-32px)] md:w-96 md:h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Chrome DevTools for full stack.</span>
              <span> </span>
              <span className="text-zinc-400/90">Use the interface you already know and love for both frontend and backend development.</span>
            </div>
            <div className="relative overflow-hidden w-[calc(100vw-32px)] h-[calc(100vw-32px)] md:w-96 md:h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Easy to integrate.</span>
              <span> </span>
              <span className="text-zinc-400/90">Subtrace requires no code changes in your app to integrate. All it takes is one command.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-48 flex justify-center">
        <div className="w-full max-w-[32rem] flex flex-col items-center rounded-lg overflow-hidden outline outline-zinc-800/80 outline-[1px] divide divide-y divide-zinc-800/80">
          <a
            className="bg-zinc-900/85 hover:bg-zinc-900/70 px-8 py-6 flex flex-col space-y-6"
            href="https://x.com/skeptrune/status/1879072724796244194"
            target="_blank"
            rel="noreferrer"
          >
            <div className="text-zinc-300 text-sm flex flex-col space-y-2">
              <p>
                I never want to build anything without
                <span> </span>
                <a target="_blank" className="hover:brightness-[1.20]" href="https://x.com/subtrace_dev" rel="noreferrer">
                  @subtrace_dev
                </a>
                <span> </span>
                configured locally ever again. Life changing piece of software.
              </p>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex justify-center item-center space-x-2 text-xs">
                <img className="mx-[2px] w-4 h-4 rounded-full" src={NickProfilePicture} />
                <a href="https://x.com/skeptrune" target="_blank" className="text-zinc-400/80 hover:brightness-[1.2] font-semibold" rel="noreferrer">
                  Nick Khami
                </a>
                <span className="text-zinc-500/80 font-medium">CEO, Trieve (YC W24)</span>
              </div>
              <img className="h-3 w-3 invert opacity-[0.50]" src={XLogo} />
            </div>
          </a>
          <div className="w-full relative group px-8 py-6 flex justify-between items-center space-x-3 md:space-x-6 bg-black">
            <img src={TrieveLogo} className="w-[4rem] md:w-[5rem] grayscale opacity-[0.50] brightness-[1.50] ml-auto" />
            <a
              href="/customers/trieve"
              className="min-w-fit max-w-fit px-4 py-2 duration-[100ms] group/button flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-900/85 outline outline-[1px] outline-zinc-800 hover:brightness-[0.90] cursor-pointer text-zinc-200"
            >
              <div>Read full case study</div>
              <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
            </a>
          </div>
        </div>
      </div>

      <div className="mt-48 flex justify-center">
        <div className="flex flex-col space-y-1 md:space-y-4 max-w-[100%]">
          <div className="text-sm grid grid-cols-2 md:grid-cols-4">
            <div className="mx-1 md:mx-2 my-1 md:my-0 max-w-48 aspect-square p-5 bg-zinc-900/90 rounded-xl">
              <span className="font-medium text-zinc-200">Built on ClickHouse.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Search through your requests using the fastest database for analytics.</span>
            </div>
            <div className="mx-1 md:mx-2 my-1 md:my-0 max-w-48 aspect-square p-5 bg-zinc-900/90 rounded-xl">
              <span className="font-medium text-zinc-200">On-prem storage.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Keep your requests in your own servers for security and data compliance.</span>
            </div>
            <div className="mx-1 md:mx-2 my-1 md:my-0 max-w-48 aspect-square p-5 bg-zinc-900/90 rounded-xl">
              <span className="font-medium text-zinc-200">Simple pricing.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Only pay for the requests you trace with strict spend limits.</span>
            </div>
            <div className="mx-1 md:mx-2 my-1 md:my-0 max-w-48 aspect-square p-5 bg-zinc-900/90 rounded-xl">
              <span className="font-medium text-zinc-200">Open source.</span>
              <span> </span>
              <ExternalLink className="font-normal text-zinc-300/95" label="Star us on GitHub." target="https://github.com/subtrace/subtrace" />
            </div>
          </div>

          <div className="mx-1 md:mx-2 h-24 flex px-6 py-3 bg-zinc-900/90 rounded-xl">
            <div className="w-full flex flex-row items-center justify-between">
              <div className="text-xl font-medium flex items-center text-zinc-200">
                <span className="text-zinc-200">Interested?</span>&nbsp;
                <span className="text-zinc-500 hidden md:block">Try for free.</span>
              </div>
              <a
                href="/login"
                className="max-w-fit whitespace-pre group/button px-8 py-3 flex justify-center items-center space-x-2 rounded w-full text-sm font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
              >
                <div>Get Started</div>
                <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-48 flex justify-center items-center">
        <NavLinks />
      </div>
    </div>
  );
}
