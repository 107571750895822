import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowDownUp, Grip, Key, Users, BookOpen, MessagesSquare, LogOut, Loader2, PanelLeftClose, PanelRightClose, Zap, CircleUserRoundIcon } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as StrictUtils from "Utils/StrictUtils";
import { Select } from "DesignComponents/Select";
import { LogoText } from "Logo";

const Navlink = (props: { path: string; icon: React.ReactElement; text: string }): React.ReactNode => {
  const location = ReactRouterDom.useLocation();
  return (
    <Link
      to={{ pathname: props.path }}
      className={[
        "flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100",
        location.pathname === props.path ? "bg-zinc-700/80 hover:bg-zinc-700" : "bg-transparent hover:bg-zinc-800",
      ].join(" ")}
    >
      <div className="w-4 h-4 flex justify-center items-center">
        <span className="w-[14px] h-[14px] flex items-center">{props.icon}</span>
      </div>
      <span>{props.text}</span>
    </Link>
  );
};

function PageSelector(props: { namespaceName: string }): React.ReactNode {
  const { lastSeenWorkerTime } = CustomHooks.useCurrentOrg();
  const showRequestsPage: boolean = lastSeenWorkerTime != null && Date.now() - new Date(lastSeenWorkerTime).valueOf() <= 7 * 24 * 3600 * 1000;

  return (
    <div className="flex flex-col space-y-1">
      <Navlink path={`/dashboard/${props.namespaceName}/live`} icon={<Zap />} text="Live" />
      {showRequestsPage ? <Navlink path={`/dashboard/${props.namespaceName}/requests`} icon={<ArrowDownUp />} text="Requests" /> : null}
      <Navlink path={`/dashboard/${props.namespaceName}/tokens`} icon={<Key />} text="Tokens" />
      <Navlink path={`/dashboard/${props.namespaceName}/users`} icon={<Users />} text="Users" />
      <Navlink path="/dashboard/namespaces" icon={<Grip />} text="Namespaces" />
    </div>
  );
}

function HelpLinks(): React.ReactNode {
  const Discord = (): React.ReactNode => (
    <svg xmlns="http://www.w3.org/2000/svg" width={127.14} height={96.36} viewBox="0 0 127.14 96.36">
      <path
        fill="#d4d4d8"
        d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
      />
    </svg>
  );

  return (
    <div className="flex flex-col space-y-1">
      <a
        href="https://subtrace.dev/discord"
        className="flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100 bg-transparent hover:bg-zinc-800"
      >
        <div className="w-4 h-4 flex justify-center items-center">
          <span className="w-[14px] h-[14px] flex items-center">
            <Discord />
          </span>
        </div>
        <span>Discord</span>
      </a>
      <a
        href="https://docs.subtrace.dev"
        className="flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100 bg-transparent hover:bg-zinc-800"
      >
        <div className="w-4 h-4 flex justify-center items-center">
          <span className="w-[14px] h-[14px] flex items-center">
            <BookOpen />
          </span>
        </div>
        <span>Docs</span>
      </a>
      <a
        href="mailto:support@subtrace.dev"
        className="flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100 bg-transparent hover:bg-zinc-800"
      >
        <div className="w-4 h-4 flex justify-center items-center">
          <span className="w-[14px] h-[14px] flex items-center">
            <MessagesSquare />
          </span>
        </div>
        <span>Support</span>
      </a>
      <a
        href="/dashboard/billing"
        className="flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs text-zinc-300 hover:text-zinc-100 bg-transparent hover:bg-zinc-800"
      >
        <div className="w-4 h-4 flex justify-center items-center">
          <span className="w-[14px] h-[14px] flex items-center">
            <CircleUserRoundIcon />
          </span>
        </div>
        <span>Billing</span>
      </a>
    </div>
  );
}

export function Navigation(): React.ReactElement {
  const { currentNamespace, namespaces, setCurrentNamespace, setNamespaces } = CustomHooks.useNamespaceManager();
  const [isHidden, setHidden] = React.useState<boolean>(localStorage.getItem("subtrace.navigation.hidden") === "true");

  const toggleHidden = (): void => {
    setHidden((prev): boolean => {
      const next = !prev;
      localStorage.setItem("subtrace.navigation.hidden", next.toString());
      return next;
    });
  };

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function getNamespaces(): Promise<void> {
      try {
        const listOrgsRequest: Api.ListOrgs_Request = {};
        const listOrgsResponse: Response = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, { signal });
        await ApiUtils.assertStatus(listOrgsResponse, 200);
        const { orgs }: Api.ListOrgs_Response = await listOrgsResponse.json();
        ArrayUtils.assertLengthAtLeast(orgs, 1);

        // We currently assume that there's only one org and we're always dealing with the first one
        const orgId: string = orgs[0].orgId;
        const request: Api.ListNamespaces_Request = { orgId };
        const response: Response = await ApiUtils.post("/api/ListNamespaces", request, { signal, subtraceTags: { org_id: orgId } });
        await ApiUtils.assertStatus(response, 200);

        const { namespaces }: Api.ListNamespaces_Response = await response.json();
        setNamespaces(namespaces);
      } catch {
        if (controller.signal.aborted) {
          // Do nothing, the API call was canceled
        }
      }
    }

    getNamespaces();

    return (): void => {
      controller.abort("Cleaning up effect getNamespaces");
    };
  }, [setNamespaces]);

  if (isHidden) {
    return (
      <div className="h-full flex flex-col justify-end py-4 px-3">
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center">
            <div></div>
            <button className="group relative pl-4 pr-[10px] py-2 flex text-zinc-600 hover:text-zinc-300" onClick={() => toggleHidden()}>
              <div className="w-[14px] h-[14px] mb-[1px] flex items-center justify-center">
                <PanelRightClose />
              </div>
              <div className="z-10 hidden group-hover:flex whitespace-pre absolute right-[calc(-100%)] top-0 h-full font-medium text-[10px] justify-center items-center">
                <span className="px-2 py-1 bg-zinc-900 rounded border border-zinc-800">Show</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="basis-1/3 grow min-w-48 max-w-60">
      <div className="w-full h-full flex flex-col justify-between py-4 px-3">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-8 pt-4">
            <div className="flex h-[20px] flex items-center">
              <div className="px-2 w-32">
                <LogoText className="text-zinc-300" />
              </div>
            </div>
            {renderNamespaceSelector()}
          </div>
          <PageSelector namespaceName={currentNamespace.name} />
        </div>
        <div className="flex flex-col space-y-4">
          <HelpLinks />
          <div className="flex justify-between items-center">
            <button className="group relative pl-4 pr-[10px] py-2 flex text-zinc-600 hover:text-zinc-300" onClick={() => toggleHidden()}>
              <div className="w-[14px] h-[14px] flex items-center justify-center">
                <PanelLeftClose />
                <div className="hidden group-hover:flex whitespace-pre absolute right-[calc(-100%)] top-0 h-full font-medium text-[10px] justify-center items-center">
                  <span className="px-2 py-1 bg-zinc-900 rounded border border-zinc-800">Hide</span>
                </div>
              </div>
            </button>
            <div className="w-full px-4 py-2 flex justify-end">
              <button
                onClick={() => (document.location = "/api/LogOut")}
                className="group relative -m-2 w-8 h-8 rounded-full text-zinc-500 hover:text-zinc-300 cursor-pointer flex justify-center items-center group relative"
              >
                <div className="w-[14px] h-[14px] flex items-center justify-center">
                  <LogOut />
                </div>
                <div className="hidden group-hover:flex whitespace-pre absolute right-[calc(100%)] top-0 h-full font-medium text-[10px] justify-center items-center">
                  <span className="px-2 py-1 bg-zinc-900 rounded border border-zinc-800">Log out</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function renderNamespaceSelector(): React.ReactNode {
    if (namespaces === undefined) {
      return (
        <div className="flex justify-between rounded-md text-zinc-600">
          <div className="flex items-center space-x-2 px-4 py-2 rounded-md font-medium text-xs">
            <div className="w-4 h-4 flex justify-center items-center">
              <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
                <Loader2 />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="px-2 flex flex-col items-center pt-2 pb-1 rounded-md font-medium text-xs text-zinc-300 bg-zinc-900/25 outline outline-zinc-900/50 hover:bg-zinc-900/50">
        <span className="px-2 self-start text-[11px] text-zinc-600">Namespace</span>
        {namespaces.length > 0 ? (
          <Select
            ariaLabel="Select namespace"
            className="-mt-1 h-8 px-2 font-medium text-xs self-stretch whitespace-pre"
            items={namespaces.map((namespace) => ({
              displayElement: namespace.name,
              itemKey: namespace.namespaceId,
              value: namespace,
            }))}
            onChange={setCurrentNamespace}
            value={StrictUtils.ensureDefined(namespaces.find((namespace) => namespace.namespaceId === currentNamespace.namespaceId))}
          />
        ) : (
          <span className="emphasis text-zinc-500">No namespace</span>
        )}
      </div>
    );
  }
}
